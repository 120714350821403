// import mockSearch from "../mockSearch.json"
import { useState } from "react";
import { Instagram, LinkedIn, Facebook, Twitter, GitHub, YouTube } from "@mui/icons-material";
// TODO:
// Add column for location, revenue estimate
// Refactor these methods to commons such as pagination

const LeadsTable = ({ leads }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(leads.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentLeads = leads.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getIcon = (network) => {
    switch (network) {
      case 'linkedin':
        return <LinkedIn className="inline-block mb-1 mr-1 text-blue-600" />;
      case 'facebook':
        return <Facebook className="inline-block mb-1 mr-1 text-blue-500" />;
      case 'github':
        return <GitHub className="inline-block mb-1 mr-1 text-gray-900" />;
      case 'twitter':
        return <Twitter className="inline-block mb-1 mr-1 text-blue-400" />;
      case 'instagram':
        return <Instagram />
      case 'youtube':
        return <YouTube />
      default:
        return null;
    }
  };

  const capitalizeFirstLetters = (title) => {
    const exceptions = ['of', 'and', 'the', 'in', 'on', 'with', 'at', 'by', 'for', 'to', 'from'];

    return title
      .split(' ')
      .map((word, index) => {
        if (exceptions.includes(word.toLowerCase()) && index !== 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  const formatLocation = (location) => {
    if (!location) return '-';
    return location.replace(', united states', '');
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        <table className="table-auto border-collapse w-full">
          <thead className="text-left">
            <tr>
              <th className="border-b border-gray-200 px-4 py-2">Name</th>
              <th className="border-b border-gray-200 px-4 py-2">Company</th>
              <th className="border-b border-gray-200 px-4 py-2">Social</th>
              <th className="border-b border-gray-200 px-4 py-2">Location</th>
              <th className="border-b border-gray-200 px-4 py-2">Employees</th>
            </tr>
          </thead>
          <tbody>
            {currentLeads.map((person, index) => (
              <tr key={index} className="border-b border-gray-200">
                <td className="px-4 py-2">
                  <div className="flex items-center">
                    <div>
                      <div className="text-md font-semibold">
                        {capitalizeFirstLetters(person.name)}
                      </div>
                      <div className="text-sm text-gray-700">
                        {person.work_email || person.raw.recommended_personal_email || "-"}
                      </div>
                      <div className="text-sm text-gray-700">
                        {person.job_title ? capitalizeFirstLetters(person.job_title) : '-'}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2 text-md">
                  {person.job_company_website ? (
                    <a
                      href={`https://${person.job_company_website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {person.job_company_website}
                    </a>
                  ) : (
                    '-'
                  )}
                </td>
                <td className="flex text-sm px-2 py-6">
                  {person.profiles ? (
                    person.profiles
                      .filter((profile, index, self) =>
                        index === self.findIndex((p) => p.network === profile.network)
                      )
                      .map((profile, index) => (
                        <a
                          key={index}
                          href={`https://${profile.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getIcon(profile.network)}
                        </a>
                      ))
                  ) : (
                    '-'
                  )}
                </td>
                <td className="px-4 py-2 text-sm">
                  {person.location ? capitalizeFirstLetters(formatLocation(person.location)) : '-'}
                </td>
                <td className="px-4 py-2 text-sm">
                  {person.job_company_size ? person.job_company_size : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <div className="flex justify-center p-2 mt-3 bg-white shadow-md">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="mx-1 px-3 py-1 bg-blue-500 text-white rounded disabled:bg-gray-300"
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="mx-1 px-3 py-1 bg-blue-500 text-white rounded disabled:bg-gray-300"
          >
            Next
          </button>
        </div>
      )}
    </div>
  )
}

export default LeadsTable;