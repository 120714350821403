import { useEffect } from 'react';
import { useSelector } from "react-redux";

const pricingTable = () => {
  const meSelector = useSelector((state) => state.me.meReducer);
  const origin = typeof window !== 'undefined' ? window.location.origin : '';
  const successUrl = `${origin}/`;
  
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const sanitizeClientReferenceId = (email) => {
    if (!email) return '';
    return email.replace(/[^a-zA-Z0-9-_ ]/g, '');
  };
  
  const clientReferenceId = meSelector.data?.email ? sanitizeClientReferenceId(meSelector.data.email) : '';
  const customerEmail = meSelector.data?.email || '';
  
  return (
    <div className="w-screen px-4 sm:px-6 lg:px-8">
      <stripe-pricing-table 
        pricing-table-id="prctbl_1QK4mUJ8nhrEI6PccusbPqQs" 
        publishable-key="pk_live_51PSo6gJ8nhrEI6PcDdAGHS5CG4Y6eNdYKwbtHs8PooWFi6AWsOenScRIgn9DD6qErBrX1GALoAACWZg3ybcfI2Ge00QhuGkuQ3"
        client-reference-id={clientReferenceId}
        customer-email={customerEmail}
        success-url={successUrl}
        cancel-url={origin}
        className="min-w-fit"
        >
      </stripe-pricing-table>
    </div>
  );
};

export default pricingTable