import { takeLatest, put, select } from "redux-saga/effects";
import { communityActions, actionTypes } from "./ducks";
import api from "../api";
import { selectAccessToken } from "authSelector";
import { pulseActions } from "pulses/ducks";

export default [
  takeLatest(actionTypes.GET_COMMUNITY_DATA, onGetCommunity),
  takeLatest(actionTypes.GET_COMMUNITY_BY_ID, onGetCommunityById),
  takeLatest(actionTypes.SAVE_COMMUNITY_DATA, onSaveCommunity),
  takeLatest(actionTypes.GET_COUNT_OF_ALL_COMMUNITIES, onGetCommunityCounts),
  takeLatest(actionTypes.GET_PULSES_BY_COMMUNITY, onGetPulseByCommunity)
];


function* onGetCommunity() {
  const accessToken = yield select(selectAccessToken);
  // TODO: This should be broken apart, we are asking a lot of the BE here, need better seperation of concerns
  while (true) {
    if (accessToken) {
      try {
        const response = yield api.get(
          'https://sonic.pulseco.ai/communities/',
          {
            Authorization: `Bearer ${accessToken}`,
          },
        );
        const allCommunityResponse = response.data;
        const communities = allCommunityResponse["communities"];
        
        if (communities && communities.length > 0) {
          const lastCommunity = communities[communities.length - 1];
          const lastCommunityId = lastCommunity.community_id;
          
          yield put(communityActions.getCommunitySuccess(allCommunityResponse));
          yield put(communityActions.getCommunityCount());
          yield put(communityActions.getCommunityById(lastCommunityId));
          yield put(communityActions.getPulseByCommunity(lastCommunityId));
          yield put(pulseActions.getPulseTypes())
        } else {
          yield put(communityActions.getCommunitySuccess(allCommunityResponse));
        }
        
        break;
      } catch (error) {
        const { response } = error;
        yield put(communityActions.getCommunityFailure(response?.data || error.message));
        break;
      }
    }
    yield;
  }
}

function* onGetCommunityById(action) {
  const { payload: communityId } = action;
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const response = yield api.get(
          `https://sonic.pulseco.ai/communities/${communityId}/leads`,
          // `http://127.0.0.1:8000/communities/${communityId}/leads`,
          {
            Authorization: `Bearer ${accessToken}`,
          },
        );
        yield put(communityActions.getCommunityByIdSuccess(communityId, response.data));
        break;
      } catch (error) {
        const { response } = error;
        yield put(communityActions.getCommunityByIdFailure(response.data));
        break;
      }
    }
    yield;
  }
}

function* onGetPulseByCommunity(action) {
  const { payload: communityId } = action;
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const response = yield api.get(
          `https://sonic.pulseco.ai/pulses/communities/${communityId}`,
          // `http://127.0.0.1:8000/pulses/communities/${action.payload}}`,
          {
            Authorization: `Bearer ${accessToken}`,
          },
        );
        yield put(communityActions.getPulseByCommunitySuccess(response.data));
        break;
      } catch (error) {
        const { response } = error;
        yield put(communityActions.getPulseByCommunityFailure(response.data));
        break;
      }
    }
    yield;
  }
}

function* onSaveCommunity(action) {
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const { query, leads, objective, total, geoId, scrollToken } = action.payload;
        const response = yield api.post(
          'https://sonic.pulseco.ai/communities/',
          // 'http://127.0.0.1:8000/communities/',
          {
            Authorization: `Bearer ${accessToken}`,
          },
          {
            query: query,
            leads: leads,
            objective: objective,
            total: total,
            geo_id: geoId,
            scroll_token: scrollToken,
          },
        );
        yield put(communityActions.saveCommunitySuccess(response.data));
        yield put(communityActions.resetPulsesByCommunity())
        yield put(communityActions.getCommunity());
        break;
      } catch (error) {
        const { response } = error;
        yield put(communityActions.saveCommunityFailure(response.data));
        break;
      }
    }
    yield;
  }
}

function* onGetCommunityCounts() {
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const response = yield api.get(
          `https://sonic.pulseco.ai/communities/count`,
          // `http://127.0.0.1:8000/communities/count`,
          {
            Authorization: `Bearer ${accessToken}`,
          }
        );
        yield put(communityActions.getCommunityCountSuccess(response.data));
        break;
      } catch (error) {
        const { response } = error;
        yield put(communityActions.getCommunityCountFailure(response.data));
        break;
      }
    }
    yield;
  }
}
