import React from 'react';
import { getPulseTypeDetails } from 'common/pulseMapping';

const NotificationCard = () => {
  const pulseDetailsArray = getPulseTypeDetails('industry_events');
  const pulseDetails = pulseDetailsArray.find(detail => detail.type === 'industry_events');

  if (!pulseDetails) {
    return <div>Error: Details for industry_events not found.</div>;
  }

  return (
    <div className="bg-gray-100 rounded-xl p-6 w-full max-w-3xl mx-auto">
      <div className="flex items-center mb-4">
        <div className="bg-blue-500 text-white p-3 rounded-full mr-4">
          {pulseDetails.icon}
        </div>
        <h3 className="text-lg font-semibold">
          {pulseDetails.title.replace('(Beta)', '').trim()}
        </h3>
      </div>
      <div className="bg-white p-4 rounded-lg mb-4 border border-gray-300">
        <div className="text-gray-700">
          <p className="font-semibold text-lg">{pulseDetails.draftExample.subject}</p>
          <div className="border-b border-gray-300 my-2"></div>
          {pulseDetails.draftExample.body.split('\n').map((line, index) => (
            <p key={index} className="text-gray-700 leading-relaxed mb-4">
              {line}
            </p>
          ))}
        </div>
      </div>
      <div className="flex justify-end items-center">
        <div className="border border-red-500 rounded-full px-4 py-2 mr-4">
            <p className='text-red-500 font-semibold'>X</p>
        </div>
        <div className="bg-blue-500 text-white py-2 px-6 rounded-lg">
          Send
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
