import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pulseActions } from 'pulses/ducks';
import { actions } from 'search-leads/ducks';
import { communityActions } from 'communities/ducks';
import { getPulseTypeDetails } from 'common/pulseMapping';
import { truncateText } from 'common/trimObjective';
import { BookmarkBorder, Bolt } from "@mui/icons-material";
import { useHistory } from 'react-router-dom';
import Loader from 'loader';
import EmptyStateModal from 'common/modals/noSearches';
// import MarketNav from './marketNav';
import MarketCards from './marketCards';

// TODO: this desperatley needs to be decoupled
// TODO: We can revist checking for communities in here for empty state, marketplace in theory need not be accessed only in presence of communities
// 1 for market, 1 for search, 1 for communities
// Fix the fkin UI and click of these fkers

const MarketPlace = ({ fromPath }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [selectedMarketCard, setSelectedMarketCard] = useState([]);
  const communitySelector = useSelector((state) => state.community)
  const pulseSelector = useSelector((state) => state.pulses)
  const searchSelector = useSelector((state) => state.search);
  const pulseTypes = pulseSelector.getPulseTypes.data
  const pulseByCommunity = useSelector((state) => state.community.getPulseByCommunity.data);
  const savedCommunityId = communitySelector.saveCommunity?.community_id
  const meSelector = useSelector((state) => state.me.meReducer)
  const communityId = communitySelector.getCommunityById.communityId


  // TODO move the resests into SAGA chain for onSaveCommunity
  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      dispatch(actions.resetLeadsData());
      dispatch(communityActions.resetCommunityIsSaved());
      dispatch(pulseActions.resetPulsesSaved());
      history.push('/pulses-to-review');
    }, 500);
  };

  useEffect(() => {
    if (!meSelector.loading && meSelector.data.sub && !pulseSelector.getPulseTypes.fetched) {
      dispatch(pulseActions.getPulseTypes());
    }
  }, [dispatch, meSelector, pulseTypes]);

  useEffect(() => {
    if (fromPath === "search") {
      const defaultCard = { subIndex: 0, type: "weather" };
      setSelectedMarketCard([defaultCard]);
    }
  }, [fromPath]);

  if (
    communitySelector.getCommunity.loading
    // || pulseSelector.savePulse.loading
    || pulseSelector.getPulseTypes.loading
    || communitySelector.getCommunityCount.loading
    || communitySelector.getCommunityById.loading
    // || communitySelector.getPulseByCommunity.loading
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="mr-4 text-lg">Loading your pulse feed...</span>
        <div className="relative">
          <Loader />
        </div>
      </div>
    );
  }
  
  if (communitySelector.getCommunity.fetched && communitySelector.getCommunity.data?.communities.length === 0) {
    return (
      <div className="flex h-screen">
        <EmptyStateModal show={true} message={"Please run and save a new search to view communities"} />
      </div>
    );
  }

  const handleSavePulse = (type) => {
    if (communityId) {
      dispatch(pulseActions.savePulses({
        communityId: communityId,
        pulseType: type,
        payload: { additional: '' },
      }));
    }
  };

  const savePulseInSearch = (type) => {
    dispatch(pulseActions.savePulses({
      communityId: savedCommunityId,
      pulseType: type,
      payload: { additional: '' },
    }));
  };

  return (
    <>
      {fromPath === "communities" ? (
        <div className="flex-1 overflow-y-auto">
          <div className="relative flex items-center justify-center mt-2">
            {pulseByCommunity.pulses.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full space-y-4">
                <h2 className="text-3xl font-semibold text-gray-800">Oops!</h2>
                <p className="text-lg text-gray-600">It looks like there's no email drafting pulses attached to this community.</p>
                {communitySelector.getPulseByCommunity.loading || pulseSelector.getPulseTypes.loading ? (
                  <div className="flex flex-col items-center justify-center">
                    <span className="text-lg mb-2">Loading available pulses...</span>
                    <div className="relative">
                      <Loader />
                    </div>
                  </div>
                ) : communitySelector.getPulseByCommunity.loading || pulseSelector.savePulse.loading ? (
                  <div className="flex flex-col items-center justify-center">
                    <span className="text-lg mb-2">Loading pulses for community...</span>
                    <div className="relative">
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex justify-center">
                    <MarketCards
                      fromPath={fromPath}
                      handleSavePulse={handleSavePulse}
                      savePulseInSearch={savePulseInSearch}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="max-w-2xl">
                <MarketCards
                  handleSavePulse={handleSavePulse}
                  savePulseInSearch={savePulseInSearch}
                  fromPath={fromPath}
                />
              </div>
            )}
          </div>
        </div >
      ) : (
        <>
          {communitySelector.getCommunity.loading
            || communitySelector.getPulseByCommunity.loading
            // || pulseSelector.savePulse.loading
            || communitySelector.getCommunityCount.loading
            || communitySelector.getCommunityById.loading
            // || communitySelector.getPulseByCommunity.loading
            ? (
              <div className="flex items-center justify-center h-screen">
                <span className="mr-4 text-lg">Loading pulses for community...</span>
                <div className="relative">
                  <Loader />
                </div>
              </div>
            ) : (
              <div className="relative h-screen">
                <div className="flex h-full flex-col">
                  {fromPath === "search" ? (
                    <div className='border-b border-gray-300'>
                      <div className='flex mt-4 mb-2'>
                        <div className='flex flex-col justify-center ml-2 mr-2'>
                          <Bolt />
                        </div>
                        <div>
                          <p className='text-lg font-semibold'>Activate Pulses to auto-draft emails</p>
                          <p className='text-md'>Emails will auto-draft in Notifications whenever a pulse hits</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='border-b border-gray-300'>
                      <div className='flex mt-4 mb-2'>
                        <div className='flex flex-col justify-center ml-2 mr-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                            <path d="M13 2L3 14H10L8 22L18 10H11L13 2Z" fill="none" stroke="currentColor" strokeWidth="1.5" />
                          </svg>
                        </div>
                        <div>
                          <p className='text-lg font-semibold'>Pulses</p>
                          <p className='text-md'>Pulses save you time by monitoring for events and writing you emails which give a reason to be opened</p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex-1 mt-4 overflow-y-auto">
                    <div className="relative flex items-center justify-center mb-8">
                      {fromPath === "search" && (
                        <>
                          <div className="flex items-center p-4 bg-gray-200 text-md rounded-full">
                            <BookmarkBorder />
                            <p className="ml-1">{truncateText(searchSelector.search.data?.objective, 55)}</p>
                          </div>
                        </>
                      )}
                    </div>
                    <MarketCards
                      handleSavePulse={handleSavePulse}
                      savePulseInSearch={savePulseInSearch}
                      fromPath={fromPath}
                    />
                  </div>
                  {fromPath === "search" && (
                    <div className="flex justify-center p-4">
                      {isLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                          <span className="ml-2 text-gray-600">Loading...</span>
                        </div>
                      ) : (
                        <button
                          className={`text-white ${selectedMarketCard.length > 0 ? 'bg-blue-500' : 'bg-gray-300 text-gray-600'} rounded-full p-3 flex items-center focus:outline-none`}
                          onClick={handleClick}
                        >
                          <p className="font-semibold text-md px-10">Finish</p>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
};

export default MarketPlace;
