import React from 'react';
import {
  Bolt,
  EventAvailable,
  NewReleasesOutlined
} from "@mui/icons-material"

const pulses = [
  {
    icon: <EventAvailable className='text-gray-600'/>,
    title: 'Professional Events',
    description: 'When a lead has industry events such as summits, networking, or conferences, be the first to reach out and connect ahead of the event.',
  },
  {
    icon: <NewReleasesOutlined className='text-gray-600'/>,
    title: 'Industry News',
    description: 'When a lead has noteable news that impacts their industry or business, be the first to connect, using the news as a merit based outreach.',
  },
];

const PulseCard = ({ icon, title, description }) => (
  <div className="relative p-6 bg-white shadow-lg rounded-lg border border-gray-200 flex flex-col h-full">
    <div className="flex items-center mb-4">
      <div className="relative flex items-center justify-center w-12 h-12 rounded-full">
        {icon}
      </div>
      <h2 className="ml-3 text-xl font-semibold">{title}</h2>
      <Bolt className="absolute top-0 right-0 mt-2 mr-2 text-blue-500"/>
    </div>
    <div className="text-gray-600 p-2 border-t flex-grow">{description}</div>
  </div>
);


const PulseComponent = () => {
  return (
    <div className="flex flex-col items-center space-y-8">
      <div className="flex space-x-4 w-full max-w-2xl mt-4">
        {pulses.map((pulse, index) => (
          <div className="flex-1" key={index}> 
            <PulseCard key={index} {...pulse} />
          </div>
        ))}
      </div>
    </div>
  );
};


export default PulseComponent;
