import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { communityActions } from "communities/ducks";
import { Bookmark, BookmarkBorder, People } from '@mui/icons-material';
import EmptyStateModal from "common/modals/noSearches.js";
import Loader from "../../loader/index.js";
import NavIconAndPage from "common/leads/navIconAndPage";
import { formatNumber } from "common/formatNumber.js";
import Pagination from "common/paginate.js";

// smart components fetch data
// dumb components render it
// pass components as props - enhancment
// clean out style tags for animation of hover

// TODO: Mocks expect pulses and map in this all community view but I believe it is not perfomant and doesn't add a large amount of value
// Discuss above bullet 
// TODO: Avoid leaning on state being passed to naviconandpage, not ideal... marking for cleanup
// TODO: Flatten this, the nested return is stupid, I was just pressed for time
// TODO: Fraction unlocked should be moved to naviconandpage for community, as we do not have length in initial get

const Communities = () => {
  const dispatch = useDispatch();
  const meSelector = useSelector((state) => state.me.meReducer)
  const [selectedCommunityId, setSelectedCommunityId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedObjective, setSelectedObjective] = useState('')
  const communitySelector = useSelector((state) => state.community);
  const communityCountsSelector = useSelector((state) => state.community);
  const communityCounts = communityCountsSelector.getCommunityCount.data;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (!meSelector.loading && meSelector.data) {
      if (!communitySelector.getCommunity.fetched && !communitySelector.getCommunity.loading) {
        dispatch(communityActions.getCommunity());
      }
    }
  }, [dispatch, communitySelector.getCommunity.fetched, communitySelector.getCommunity.loading, meSelector.loading, meSelector.data]);


  const handleCommunityClick = (community_id, objective) => {
    setSelectedCommunityId(community_id);
    setSelectedObjective(objective)
    dispatch(communityActions.getCommunityById(community_id));
    dispatch(communityActions.getPulseByCommunity(community_id));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCommunities = communitySelector.getCommunity.data?.communities?.filter(community =>
    community.objective.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];


  const totalPages = Math.ceil(filteredCommunities.length / itemsPerPage);
  const paginatedCommunities = filteredCommunities.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (communitySelector.getCommunity.fetched && communitySelector.getCommunity.data?.communities.length === 0) {
    return (
      <div className="flex h-screen">
        <EmptyStateModal show={true} message={"Please run and save a new search to view communities"} />
      </div>
    );
  }

  return (
    <div className="flex justify-center w-full">
      {meSelector.loading || communitySelector.getCommunity.loading || communityCountsSelector.getCommunityCount.loading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="flex flex-col items-center">
            <Loader />
            <p className="mr-4 mt-10 text-lg">Loading your Searches...</p>
          </div>
        </div>
      ) : (
        <>
          {!selectedCommunityId ? (
            <div className="w-full max-w-5xl p-6 mt-2">
              <div className="flex justify-between items-center mb-4">
                <input
                  type="text"
                  placeholder="Search communities"
                  className="border border-gray-300 p-2 rounded-lg w-1/3"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              <div className="space-y-4">
                {paginatedCommunities.map((community) => {
                  const countData = communityCounts.find(
                    (count) => count.community_id === community.community_id
                  ) || { total: 0, lead_count: 0 };

                  return (
                    <div
                      key={community.community_id}
                      className="flex justify-between items-center p-4 bg-white shadow rounded-lg border cursor-pointer"
                      onClick={() => handleCommunityClick(community.community_id, community.objective)}
                    >
                      <div className="flex-1">
                        <h2 className="text-lg font-semibold">
                          {community.objective?.substring(0, 70) + (community.objective?.length > 70 ? '...' : '')}
                        </h2>
                      </div>
                      <div className="flex items-center">
                        <People className="mr-2" />
                        <span className="text-blue-500 font-semibold">
                          {formatNumber(countData.lead_count)} / {formatNumber(countData.total)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              {filteredCommunities.length > itemsPerPage && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          ) : (
            <div className="flex flex-col w-full">
              {communitySelector.getCommunityById.loading ? (
                <div className="flex items-center justify-center h-screen">
                  <Loader />
                </div>
              ) : (
                <NavIconAndPage
                  leads={communitySelector.getCommunityById.data}
                  fromPath={"communities"}
                  setSelectedCommunityId={setSelectedCommunityId}
                  selectedObjective={selectedObjective}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Communities;