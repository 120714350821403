import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Close, MoreHoriz, ThumbUp, LinkedIn, Edit } from '@mui/icons-material';
import { actions } from 'users/ducks';
import { pulseActions } from './ducks';
import { differenceInDays } from 'date-fns';
import Loader from 'loader';
import { getPulseIcon } from 'common/getPulseTypeIcon';

// TODO:
// Attach do not pulse to MoreHoriz button, accompanying error modal catch
// Bring quill into this, enable patch drafts & other crud actions
// Popout should only occupy space left by the card translating left

// TODO: use getPulseIcon in market too this can be better normalized
// TODO: This desperatley needs a refactor, Zion & Andrew to clean this into best practices
// 

const draftCards = ({ notifications }) => {
  const dispatch = useDispatch();
  const [activePulse, setActivePulse] = useState(null);

  const sendSelector = useSelector((state) => state.me.sendEmails);
  const meSelector = useSelector((state) => state.me.meReducer)
  const [editableNotifications, setEditableNotifications] = useState({});
  const [editMode, setEditMode] = useState({});
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [sentPulses, setSentPulses] = useState([]);
  const [sentCount, setSentCount] = useState(0);
  const [acknowledgedCount, setAcknowledgedCount] = useState(0);

  useEffect(() => {
    if (sentCount + acknowledgedCount === notifications.length) {
      dispatch(pulseActions.getPulseNotifications());
    }
  }, [sentCount, acknowledgedCount, notifications.length, dispatch]);

  const handleMoreClick = (index) => {
    setActivePulse(index);
  };

  const handleClosePopout = () => {
    setActivePulse(null);
  };

  // TODO: confirm if user_id or email, same thing but which is persisting from the GCP call?
  // TODO: Capitalizing stuff can be in commons
  // TODO: This method is handling a lot, we can break it into helpers to prepare mails -> handle editing too!, 
  // then one for the sending itself, we should additionally encorporate an async option to only dispatch aknowledged after 200 from send, we can do this by unsubscribing from the store I believe
  const handleSend = (subject, recipient, message, notificationId) => {
    const emailDetails = {
      sender: meSelector.data?.user_info.user_id,
      to: recipient,
      subject: subject,
      body: `<p>${message.replace(/\n/g, '<br>')}</p>`,
    };

    setLoadingIndex(notificationId);
    dispatch(actions.sendEmail(emailDetails));

    setTimeout(() => {
      setLoadingIndex(null);
      setSentPulses((prev) => [...prev, notificationId]);
      setSentCount((prev) => prev + 1);
      dispatch(pulseActions.acknowledgePulse({
        notificationId: notificationId,
        acknowledged: true,
      }));
    }, 500);
  };

  const handleAcknowledgePulse = (notificationId) => {

    setLoadingIndex(notificationId);

    dispatch(pulseActions.acknowledgePulse({
      notificationId: notificationId,
      acknowledged: true,
    }));

    setTimeout(() => {
      setLoadingIndex(null);
      setSentPulses((prev) => [...prev, notificationId]);
      setAcknowledgedCount((prev) => prev + 1);
    }, 500);
  };

  const capitalizeEachWord = (text) => {
    if (typeof text !== 'string') return text;
    return text
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    const initialState = {};
    const initialEditMode = {};
    notifications.forEach((pulse) => {
      initialState[pulse.notification_id] = {
        subject: pulse.payload.subject,
        body: pulse.payload.body,
      };
      initialEditMode[pulse.notification_id] = false;
    });
    setEditableNotifications(initialState);
    setEditMode(initialEditMode);
  }, [notifications]);


  const handleInputChange = (notificationId, field, value) => {
    setEditableNotifications((prevState) => ({
      ...prevState,
      [notificationId]: {
        ...prevState[notificationId],
        [field]: value,
      },
    }));
  };

  const toggleEditMode = (notificationId) => {
    setEditMode((prevState) => ({
      ...prevState,
      [notificationId]: !prevState[notificationId],
    }));
  };

  return (
    <div className="relative">
      {sentCount > 0 && (
        <div className="fixed top-10 left-5 ml-2 mt-2 text-blue-500 font-semibold px-4 py-2">
          Sent {sentCount} Emails
        </div>
      )}
      <div>
        {notifications.slice().reverse().map((pulse, index) => (
          !sentPulses.includes(pulse.notification_id) && (
            <div key={pulse.notification_id} className={`relative mb-4 mt-6 ${activePulse === pulse.notification_id ? 'flex items-start' : 'block'}`}>
              {pulse.payload_type === "string" ? (
                <div className="bg-gray-100 rounded-xl p-2 mt-4 w-1/2 max-w-1/2 mx-auto relative">
                  {loadingIndex === pulse.notification_id && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-200 opacity-75 z-10">
                      <Loader />
                    </div>
                  )}
                  <div className="flex justify-center items-center mb-4 p-4">
                    {getPulseIcon(pulse['pulse_type'])}
                  </div>
                  <div className="text-center text-lg font-semibold p-4">
                    {pulse.community_details.objective || "New leads have been found for one of your searches."}
                  </div>
                  <div className="flex justify-end p-3 text-blue-500">
                    <button
                      className="bg-blue-500 text-white px-6 py-3 rounded-full mr-2 flex items-center"
                      onClick={() => handleAcknowledgePulse(pulse.notification_id)}
                    >
                      <ThumbUp />
                    </button>
                  </div>
                </div>
              ) : (
                <div className={`transition-transform duration-300 ${activePulse === pulse.notification_id ? 'transform -translate-x-1' : ''}`}>
                  <div className="p-2 w-1/2 max-w-1/2 mx-auto relative mb-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        {getPulseIcon(pulse['pulse_type'])}
                        <h2 className="text-2xl font-semibold ml-4">{pulse['pulse_type'] === "industry_events" ? "Industry Events" : pulse['pulse_type']}</h2>
                      </div>
                      <button onClick={() => handleMoreClick(pulse.notification_id)}>
                        <MoreHoriz />
                      </button>
                    </div>
                  </div>

                  <div className="bg-gray-100 rounded-xl p-2 w-1/2 max-w-1/2 mx-auto relative">
                    {loadingIndex === pulse.notification_id && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-200 opacity-75 z-10">
                        <Loader />
                      </div>
                    )}

                    <div className="flex items-center mb-2 p-3">
                      <div className="flex justify-between w-full">
                        <div>
                          <h3 className="text-lg font-semibold">
                            <a
                              href={pulse.lead_id.startsWith('http') ? pulse.lead_id : `https://${pulse.lead_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {capitalizeEachWord(pulse.lead_details.name)}
                              <LinkedIn className='text-blue-500 ml-1' />
                            </a>
                          </h3>
                          <p className="text-sm text-gray-600">
                            {capitalizeEachWord(pulse.lead_details.job_title)} at
                            <a
                              className="text-sm text-gray-600 underline ml-1"
                              href={pulse.lead_details.company_website.startsWith('http') ? pulse.lead_details.company_website : `https://${pulse.lead_details.company_website}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {capitalizeEachWord(pulse.lead_details.company_name)}
                            </a>
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm text-gray-600">
                            {capitalizeEachWord(pulse.lead_details.location).split(',').slice(0, 2).join(', ')}
                          </p>
                          <p className="text-sm text-gray-600">
                            {(() => {
                              const pulseDate = new Date(pulse.created_at);
                              const today = new Date();
                              const daysAgo = differenceInDays(today, pulseDate);

                              return daysAgo === 0 ? "Pulse hit today" : `Pulse hit ${daysAgo} days ago`;
                            })()}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mr-2 ml-4 mt-4">
                      {editMode[pulse.notification_id] ? (
                        <input
                          type="text"
                          className="font-semibold bg-white w-full p-2 border-b border-gray-300"
                          value={editableNotifications[pulse.notification_id]?.subject || ''}
                          onChange={(e) => handleInputChange(pulse.notification_id, 'subject', e.target.value)}
                        />
                      ) : (
                        <h4 className="font-semibold">{editableNotifications[pulse.notification_id]?.subject || pulse.payload.subject}</h4>
                      )}
                      <button onClick={() => toggleEditMode(pulse.notification_id)}>
                        {editMode[pulse.notification_id] ? <div className='border border-blue-500 rounded-full p-2 ml-2'>Save</div> : <Edit className="ml-2 text-gray-500" />}
                      </button>
                    </div>
                    <div className="p-2">
                      <div className="border border-gray-300 ml-2 mr-2 mb-4"></div>
                      {editMode[pulse.notification_id] ? (
                        <textarea
                          className="text-gray-800 p-2 mb-4 bg-white w-full border border-gray-300 resize-none"
                          style={{ whiteSpace: 'pre-line', minHeight: '300px' }}
                          value={editableNotifications[pulse.notification_id]?.body || ''}
                          onChange={(e) => handleInputChange(pulse.notification_id, 'body', e.target.value)}
                        />
                      ) : (
                        <p className="text-gray-800 p-2 mb-4 min-h-[200px] whitespace-pre-line box-border">
                          {editableNotifications[pulse.notification_id]?.body || pulse.payload.body}
                        </p>
                      )}
                    </div>
                    <div className="flex justify-end p-3">
                      <button
                        className="text-red-500 text-sm px-2 py-2 rounded-full flex items-center mr-4"
                        onClick={() => handleAcknowledgePulse(pulse.notification_id)}
                      >
                        <Close />
                      </button>
                      <button
                        className="bg-blue-500 text-white px-6 py-3 rounded-full mr-2 flex items-center"
                        onClick={() => handleSend(editableNotifications[pulse.notification_id]?.subject || pulse.payload.subject, pulse.lead_details.work_email, editableNotifications[pulse.notification_id]?.body || pulse.payload.body, pulse.notification_id)}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {activePulse === pulse.notification_id && pulse.payload_type !== "string" && (
                <div className="bg-white border border-gray-200 rounded-lg shadow-lg">
                  <button
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={() => {
                      // Logic for DNP patch here
                      handleClosePopout();
                    }}
                  >
                    Do Not Pulse
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={handleClosePopout}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          )
        ))}
      </div>
    </div >
  )
}

export default draftCards