// EmptyStateModal.js
import React from 'react';
import { Link } from 'react-router-dom';

const EmptyStateModal = ({ show, message }) => {
  if (!show) return null;

  return (
    <div className="absolute inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <p>{message}</p>
        <Link to="/" className="mt-4 inline-block bg-blue-500 text-white p-2 rounded-lg">
          Start Search
        </Link>
      </div>
    </div>
  );
};

export default EmptyStateModal;
