import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import authReducer from "./authSlice"
import meRootReducer, { initialState as meInitialState } from "users/ducks"
import onboardingReducer from "onboarding/onboardingSlice";
import {
  sideEffectMiddleware,
  sideEffectReducer,
} from "@faizaanceg/redux-side-effect";
import { snitchReducer, snitchMiddleware } from "@faizaanceg/snitch";
import searchReducers, { initialState as searchInitialState } from "search-leads/ducks";
import pulsesRootReducer, { initialState as pulsesInitialState } from "pulses/ducks"
import communityRootReducer, { initialState as communityInitialState } from "communities/ducks"
import createSagaMiddleware from "redux-saga";
import mainSaga from "sagas";
import logger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [
  sagaMiddleware,
  logger,
  sideEffectMiddleware("sideEffect"),
  snitchMiddleware("modals"),
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  auth: authReducer,
  me: meRootReducer,
  onboarding: onboardingReducer,
  search: searchReducers,
  community: communityRootReducer,
  pulses: pulsesRootReducer,
  sideEffect: sideEffectReducer,
  modals: snitchReducer,
});

const initialState = {
  ...meInitialState,
  ...communityInitialState,
  ...searchInitialState,
  ...pulsesInitialState
};

export default () => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(mainSaga);
  return store;
};
