import { all } from "redux-saga/effects";
import MeSagas from "./users/sagas"
import CommonSagas from "common/sagas";
import communitySagas from "./communities/sagas"
import searchLeadsSagas from "./search-leads/sagas"
import pulsesSagas from "./pulses/sagas"

// TODO: Remove loger from prod URL, and remove the unescessary while token logic its spaghetti and bad Zion u fker!

const sagasToListenTo = [
  ...CommonSagas,
  ...MeSagas,
  ...communitySagas,
  ...searchLeadsSagas,
  ...pulsesSagas,
];


export default function* mainSaga() {
  yield all(sagasToListenTo);
}
