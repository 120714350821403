import { takeLatest, put, call, select } from "redux-saga/effects";
import { pulseActions, actionTypes } from "./ducks";
import api from "../api";
import { selectAccessToken } from "authSelector";
import { communityActions } from "communities/ducks";

export default [
  takeLatest(actionTypes.SAVE_PULSE_DATA, onSavePulse),
  takeLatest(actionTypes.GET_ACTIVATED_PULSES, onGetActivatedPulses),
  takeLatest(actionTypes.GET_PULSE_TYPES, onGetPulseTypes),
  takeLatest(actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER, onGetPulseNotificationsByUser),
  takeLatest(actionTypes.ACKNOLEDGE_PULSE, onAcknowledgePulse),
];

// TODO: Save pulses should fetch new copy of communities so communities tab is not stale
// It should also refetch count so this isn't stale on pulses feed page

function* onSavePulse(action) {
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const { communityId, pulseType, payload } = action.payload;
        const response = yield api.post(
          'https://sonic.pulseco.ai/pulses/',
          // 'http://127.0.0.1:8000/pulses',
          {
            Authorization: `Bearer ${accessToken}`,
          },
          {
            community_id: communityId,
            pulse_type: pulseType,
            payload: payload
          }
        );
        yield put(pulseActions.savePulseSuccess(response.data));
        // yield put(communityActions.getCommunityCount());
        yield put(communityActions.getPulseByCommunity(communityId));
        // yield put(pulseActions.getActivatedPulseTypes());
        break;
      } catch (error) {
        const { response } = error;
        yield put(pulseActions.savePulseFailure(response.data));
        break;
      }
    }
    yield;
  }
}

function* onGetActivatedPulses() {
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const response = yield api.get(
          "https://sonic.pulseco.ai/pulses/",
          // "http://127.0.0.1:8000/pulses",
          {
            Authorization: `Bearer ${accessToken}`,
          },
        );
        yield put(pulseActions.getActivatedPulseTypesSuccess(response.data));
        yield put(communityActions.getCommunityCount());
        break;
      } catch (error) {
        const { response } = error;
        yield put(pulseActions.getActivatedPulseTypesFailure(response.data))
        break;
      }
    }
    yield;
  }
}

function* onGetPulseTypes() {
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const response = yield api.get(
          "https://sonic.pulseco.ai/pulses/types",
          // "http://127.0.0.1:8000/pulses/types",
          {
            Authorization: `Bearer ${accessToken}`,
          },
        );
        yield put(pulseActions.getPulseTypesSuccess(response.data));
        break;
      } catch (error) {
        const { response } = error;
        yield put(pulseActions.getPulseTypesFailure(response.data))
        break;
      }
    }
    yield;
  }
}

function* onGetPulseNotificationsByUser() {
  while (true) {
    const accessToken = yield select(selectAccessToken);
    if (accessToken) {
      try {
        const response = yield api.get(
          "https://sonic.pulseco.ai/pulse_notifications/",
          // "http://127.0.0.1:8000/pulse_notifications",
          {
            Authorization: `Bearer ${accessToken}`,
          },
        );
        yield put(pulseActions.getPulseNotificationsSuccess(response.data));
        break;
      } catch (error) {
        const { response } = error;
        yield put(pulseActions.getPulseNotificationsFailure(response.data));
        break;
      }
    }
    yield;
  }
}

function* onAcknowledgePulse(action) {
  const accessToken = yield select(selectAccessToken);
  if (accessToken) {
    try {
      const { notificationId, acknowledged } = action.payload;
      const response = yield api.put(
        "https://sonic.pulseco.ai/pulse_notifications/",
        {
          Authorization: `Bearer ${accessToken}`,
        },
        { 
          notification_id: notificationId,
          acknowledged: acknowledged, 
        },
      );
      yield put(pulseActions.acknowledgePulseSuccess(response.data));
    } catch (error) {
      const { response } = error;
      yield put(pulseActions.acknowledgePulseFailure(response?.data || {}));
    }
  }
}

