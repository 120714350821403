import { useState, useEffect } from 'react';
import StepOne from './stepOne';
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour'
import PricingTable from './pricingTable';
import {
  ArrowBackIos,
  ArrowForwardIos,
  People,
  Bolt,
  NotificationsOutlined
} from "@mui/icons-material";
import { useDispatch } from 'react-redux';
import { actions } from 'users/ducks';
// import { completeOnboarding } from './onboardingSlice';

// TODO: Do not render pricing table if coins exist for user_id on users table

const Onboarding = () => {
  const dispatch = useDispatch();
  const savedStep = localStorage.getItem("onboardingStep");
  const [step, setStep] = useState(savedStep ? parseInt(savedStep) : 0);

  // TODO: Include stricter URL check here
  // Can also probably benefit by storing referring in local
  useEffect(() => {
    const lastUrl = document.referrer;
    if (lastUrl.includes('stripe.com/checkout')) {
      setStep(4);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("onboardingStep", step);
  }, [step]);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));

  const steps = [
    { content: <StepOne />, title: "Search for people in natural language", icon: <People fontSize='large' /> },
    {
      content: <StepTwo />, title: "Save countless hours with our merit based outreach called: Pulses", 
      icon: 
      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-12 mt-5">
        <path d="M13 2L3 14H10L8 22L18 10H11L13 2Z" fill="none" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    },
    { content: <StepThree />, title: "Emails draft the moment a Pulse is triggered", icon: <NotificationsOutlined fontSize='large'/> },
    { content: <PricingTable />, title: "Select your plan"},
    { content: <StepFour /> },
  ];

  return (
    <div className="onboarding flex flex-col justify-center items-center min-h-screen">
      <div className="flex flex-col items-center justify-center flex-grow">
        {steps[step].image ? (
          <img
            src={steps[step].image}
            alt="step icon"
            className="w-20 h-20 rounded-full mb-6"
          />
        ) : (
          <div>{steps[step].icon}</div>
        )}
        <h2 className="mb-10 text-4xl text-center">{steps[step].title}</h2>
        <div className={`${step === 3 ? "w-full flex justify-center" : "w-auto"}`}>
          {steps[step].content}
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 flex justify-center mb-8 mt-4">
        <div className="flex items-center">
          {step > 0 && (
            <button onClick={prevStep} className="text-gray-500 hover:text-gray-700 mr-20 focus:outline-none">
              <div className="flex">
                <ArrowBackIos />
                <p className="ml-2">Back</p>
              </div>
            </button>
          )}
          {steps.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 rounded-full mx-2 cursor-pointer ${index === step ? "bg-gray-900" : "bg-gray-300"}`}
              onClick={() => setStep(index)}
            ></div>
          ))}
          {step < steps.length - 1 ? (
            <button onClick={nextStep} className="bg-black text-white px-4 py-2 rounded-full hover:bg-gray-800 ml-20 focus:outline-none">
              <div className="flex">
                <p className="mr-2">Next</p>
                <ArrowForwardIos />
              </div>
            </button>
          ) : (
            <button onClick={() => dispatch(actions.updateOnboarding())} className="bg-black text-white px-4 py-2 rounded-full ml-20 hover:bg-gray-800 focus:outline-none">
              Complete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;