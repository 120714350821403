import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Bolt,
  People,
  PeopleOutline,
  Person,
  Notifications,
  NotificationsOutlined,
  TollOutlined
} from "@mui/icons-material";
import useProfilePicture from "common/useProfilePicture";

// TODO: Review profile here or in app to display pop up of currency etc

const Navigator = () => {
  const meLoading = useSelector((state) => state.me.meReducer.loading)
  const meSelector = useSelector((state) => state.me.meReducer.data)
  const [activeTab, setActiveTab] = useState('/');
  const location = useLocation();
  const profilePic = useProfilePicture();

  const menuItems = [
    {
      label: 'Notifications',
      link: '/pulses-to-review',
      activeIcon: <Notifications />,
      inactiveIcon: <NotificationsOutlined />
    },
    {
      label: 'People',
      link: '/communities',
      activeIcon: <People />,
      inactiveIcon: <PeopleOutline />
    },
    {
      label: 'Pulses',
      link: '/pulses',
      activeIcon: <Bolt className="w-6 h-6" />,
      inactiveIcon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-12 mt-5">
          <path d="M13 2L3 14H10L8 22L18 10H11L13 2Z" fill="none" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      ),
    },
  ];

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  return (
    <nav className="fixed top-0 left-0 flex flex-col h-screen border-r border-gray-400 w-40 bg-white z-20">
      <Link to="/">
        <div className="flex ml-2 mr-2 mt-5 mb-10 rounded-lg p-2 rounded-full bg-gray-300">
          <div className="flex items-center w-6">
            <img src={`${process.env.PUBLIC_URL}/pulse-logo-icon.png`} alt="Logo" className="rounded-full" />
          </div>
          <p className="ml-3">Search</p>
        </div>
      </Link>
      <div className="flex-1 overflow-auto">
        {menuItems.map((item) => (
          <div key={item.label}>
            <div className={`flex ml-2 mr-2 h-12 rounded-lg ${activeTab === item.link ? 'bg-gray-300 font-bold' : 'hover:bg-gray-100'}`}>
              <Link to={item.link} className="flex items-center justify-between text-gray-800">
                <div className={`flex items-center text-sm ml-2 gap-2 ${activeTab === item.link ? "font-bold" : ''}`}>
                  {activeTab === item.link ? item.activeIcon : item.inactiveIcon}
                  <p className="ml-1">{item.label}</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center mb-10">
        <Link to="/profile">
          {meLoading ? (
            <Spinner />
          ) : (
            profilePic ? (
              <div className="flex flex-col items-center">
                <img
                  src={profilePic}
                  referrerPolicy="no-referrer"
                  alt="user profile picture"
                  className="rounded-full w-16 h-16"
                />
                <div className="flex items-center justify-center mt-2">
                  <TollOutlined className="text-yellow-500 mr-1" />
                  <p>
                    {meSelector?.user_info?.coins === undefined || meSelector?.user_info?.coins === null
                      ? "Add Coins"
                      : meSelector?.user_info.coins === 0
                        ? "Add Coins"
                        : meSelector?.user_info.coins}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <Person className="rounded-full w-16 h-16" />
                <div className="flex items-center justify-center mt-2">
                  <TollOutlined className="text-yellow-500 mr-1" />
                  <p>
                    {meSelector?.user_info?.coins === undefined || meSelector?.user_info?.coins === null
                      ? "Add Coins"
                      : meSelector?.user_info.coins === 0
                        ? "Add Coins"
                        : meSelector?.user_info.coins}
                  </p>
                </div>
              </div>
            )
          )}
        </Link>
      </div >
    </nav >
  );
};

export default Navigator;
