import { isSuccess, isFailure } from "common/util";
import { combineReducers } from "redux"; 

export const actionTypes = {
  GET_COMMUNITY_DATA: "[Community] Get Community Data",
  GET_COMMUNITY_BY_ID: "[Community] Get Community By ID",
  SAVE_COMMUNITY_DATA: "[Community] Save Community",
  RESET_COMMUNITY_IS_SAVED: "[Community] Reset community is saved",
  GET_COUNT_OF_ALL_COMMUNITIES: "[Community] Count all community details",
  GET_PULSES_BY_COMMUNITY: "[Pulses] Get pulses by community_id",
  RESET_PULSES_BY_COMMUNITY: "[Pulses] Reset pulses by community",
};

export const communityActions = {
  getCommunity: (data) => ({
    type: actionTypes.GET_COMMUNITY_DATA,
    data
  }),
  getCommunitySuccess: (data) => ({
    type: isSuccess(actionTypes.GET_COMMUNITY_DATA),
    data
  }),
  getCommunityFailure: (error) => ({
    type: isFailure(actionTypes.GET_COMMUNITY_DATA),
    error
  }),
  getCommunityById: (id) => ({
    type: actionTypes.GET_COMMUNITY_BY_ID,
    payload: id,
  }),
  getCommunityByIdSuccess: (id, data) => ({
    type: isSuccess(actionTypes.GET_COMMUNITY_BY_ID),
    id,
    data,
  }),
  getCommunityByIdFailure: (error) => ({
    type: isFailure(actionTypes.GET_COMMUNITY_BY_ID),
    error,
  }),
  getPulseByCommunity: (id) => ({
    type: actionTypes.GET_PULSES_BY_COMMUNITY,
    payload: id,
  }),
  getPulseByCommunitySuccess: (data) => ({
    type: isSuccess(actionTypes.GET_PULSES_BY_COMMUNITY),
    data
  }),
  getPulseByCommunityFailure: (error) => ({
    type: isFailure(actionTypes.GET_PULSES_BY_COMMUNITY),
    error,
  }),
  saveCommunity: (payload) => ({
    type: actionTypes.SAVE_COMMUNITY_DATA,
    payload,
  }),
  saveCommunitySuccess: (data) => ({
    type: isSuccess(actionTypes.SAVE_COMMUNITY_DATA),
    data
  }),
  saveCommunityFailure: (error) => ({
    type: isFailure(actionTypes.SAVE_COMMUNITY_DATA),
    error
  }),
  resetCommunityIsSaved: () => ({
    type: actionTypes.RESET_COMMUNITY_IS_SAVED,
  }),
  resetPulsesByCommunity: () => ({
    type: actionTypes.RESET_PULSES_BY_COMMUNITY,
  }),
  getCommunityCount: (data) => ({
    type: actionTypes.GET_COUNT_OF_ALL_COMMUNITIES,
    payload: data,
  }),
  getCommunityCountSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_COUNT_OF_ALL_COMMUNITIES),
    data,
  }),
  getCommunityCountFailure: (error) => ({
    type: isFailure(actionTypes.GET_COUNT_OF_ALL_COMMUNITIES),
    error,
  }),
};

// More verbose initial state heps us fail more gracefully, avoid null use empty array to avoid app breaking
const getCommunityInitialState = {
  data: [],
  objective: "",
  fetched: false,
  loading: false,
  error: '',
};

const getCommunityByIdInitialState = {
  data: [],
  loading: false,
  fetched: false,
  error: '',
};

const saveCommunityInitialState = {
  community_id: '',
  communityIsSaved: false,
  loading: false,
  error: '',
};

const getCommunityCountsInitialState = {
  loading: false,
  fetched: false,
  data: []
}

const getPulseByCommunityInitialState = {
  data: [],
  loading: false,
  error: null
}


export const initialState = {
  getCommunity: getCommunityInitialState,
  getCommunityById: getCommunityByIdInitialState,
  getPulseByCommunity: getPulseByCommunityInitialState,
  saveCommunity: saveCommunityInitialState,
  getCommunityCounts: getCommunityCountsInitialState,
}

function getCommunityReducer(state = getCommunityInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_COMMUNITY_DATA:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case isSuccess(actionTypes.GET_COMMUNITY_DATA):
      return {
        ...state,
        data: action.data,
        fetched: true,
        objective: action.data.objective,
        loading: false,
        error: null,
      };
    case isFailure(actionTypes.GET_COMMUNITY_DATA):
      return {
        ...state,
        loading: false,
        fetched: true,
        error: action.error,
      };
    default:
      return state;
  }
}

function getCommunityByIdReducer(state = getCommunityByIdInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_COMMUNITY_BY_ID:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case isSuccess(actionTypes.GET_COMMUNITY_BY_ID):
      return {
        ...state,
        data: action.data,
        communityId: action.id,
        fetched: true,
        loading: false,
        error: null,
      };
    case isFailure(actionTypes.GET_COMMUNITY_BY_ID):
      return {
        ...state,
        fetched: true,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

function getPulseByCommunityReducer(state = getPulseByCommunityInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_PULSES_BY_COMMUNITY:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case isSuccess(actionTypes.GET_PULSES_BY_COMMUNITY):
      return {
        ...state,
        data: action.data,
        error: null,
        loading: false,
      };  
    case isFailure(actionTypes.GET_PULSES_BY_COMMUNITY):
      return {
        ...state,
        error: action.error,
        loading: false,
      };  
      case actionTypes.RESET_PULSES_BY_COMMUNITY:
        return {
          ...state,
          data: [],
          error: null,
          loading: false
        };
    default:
      return state;
  }
}

function saveCommunityReducer(state = saveCommunityInitialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_COMMUNITY_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case isSuccess(actionTypes.SAVE_COMMUNITY_DATA):
      return {
        ...state,
        community_id: action.data.community_id,
        communityIsSaved: true,
        loading: false,
        error: null,
      };
    case isFailure(actionTypes.SAVE_COMMUNITY_DATA):
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      case actionTypes.RESET_COMMUNITY_IS_SAVED:
        return {
          ...state,
          communityIsSaved: false,
        };
    default:
      return state;
  }
}

function getCommunityCountReducer(state = getCommunityCountsInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_COUNT_OF_ALL_COMMUNITIES:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case isSuccess(actionTypes.GET_COUNT_OF_ALL_COMMUNITIES):
      return {
        ...state,
        data: action.data,
        fetched: true,
        loading: false,
        error: null,
      };
    case isFailure(actionTypes.GET_COUNT_OF_ALL_COMMUNITIES):
      return {
        ...state,
        loading: false,
        fetched: true,
        error: action.error,
      };
    default:
      return state;
  }
}

const communityRootReducer = combineReducers({
  getCommunity: getCommunityReducer,
  getCommunityById: getCommunityByIdReducer,
  getPulseByCommunity: getPulseByCommunityReducer,
  saveCommunity: saveCommunityReducer,
  getCommunityCount: getCommunityCountReducer,
});

export default communityRootReducer;