import { isSuccess, isFailure } from "./util";

export const actionTypes = {
  COPY_TO_CLIPBOARD: "[Common] Copy to clipboard",
  UPLOAD_IMAGE: "[Common] Upload Image to s3",
};

export const actions = {
  copyToClipboard: () => ({
    type: isSuccess(actionTypes.COPY_TO_CLIPBOARD),
    notify: "Link has been copied to clipboard",
  }),
  uploadImage: (uploadPath, next, file) => ({
    type: actionTypes.UPLOAD_IMAGE,
    uploadPath,
    next,
    file,
  }),
  uploadImageSuccess: () => ({
    type: isSuccess(actionTypes.UPLOAD_IMAGE),
  }),
  uploadImageFailure: (error) => ({
    type: isFailure(actionTypes.UPLOAD_IMAGE),
    error,
  }),
};
