import { SportsBasketball, Person, Cloud, Celebration, AttachMoney, Article, People, EventAvailable } from '@mui/icons-material';

export const getPulseIcon = (pulseType) => {
  switch (pulseType) {
    case 'refresh':
      return (
        <div className='flex items-center justify-center bg-blue-500 w-12 h-12 rounded-full'>
          <People className="text-white" fontSize='large'/>
        </div>
      )
    case 'weather':
      return (
        <div className='flex items-center justify-center bg-blue-500 w-12 h-12 rounded-full'>
          <Cloud className="text-white" fontSize='large' />
        </div>
      )
    case 'sports':
      return (
        <div className='flex items-center justify-center w-12 h-12 rounded-full'>
          <SportsBasketball className="text-orange-600" fontSize='large'/>
        </div>
      )
    case 'industry_events':
      return (
        <div className='flex items-center justify-center w-12 h-12 rounded-full'>
          <EventAvailable className="text-gray-600" fontSize='large'/>
        </div>
      )
    case 'funding':
      return (
        <div className='flex items-center justify-center bg-green-500 w-12 h-12 rounded-full'>
          <AttachMoney className="text-white" />
        </div>
      )
    case 'promotion':
      return (
        <div className='flex items-center justify-center bg-yellow-500 w-12 h-12 rounded-full'>
          <Celebration className="text-white" />
        </div>
      )
    case 'article posted':
      return (
        <div className='flex items-center justify-center bg-blue-500 w-12 h-12 rounded-full'>
          <Article className="text-white" />
        </div>
      )
    default:
      return <Person className="text-white" />;
  }
};