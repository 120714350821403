import { isSuccess, isFailure } from "common/util";

export const actionTypes = {
  SEARCH_LEADS_DATA: "[Leads] Search leads",
  RESET_LEADS_DATA: "[Leads] Reset Search leads"
};

export const actions = {
  searchLeads: (payload) => ({
    type: actionTypes.SEARCH_LEADS_DATA,
    payload,
  }),  
  searchLeadsSuccess: (data) => ({
    type: isSuccess(actionTypes.SEARCH_LEADS_DATA),
    data
  }),
  searchLeadsFailure: (error) => ({
    type: isFailure(actionTypes.SEARCH_LEADS_DATA),
    error,
  }),
  resetLeadsData: (data) => ({
    type: actionTypes.RESET_LEADS_DATA,
    data
  })
};

export const initialState = {
  search: {
    objective: "",
    search_strategy: "PDL_STRATEGY",
    chain_strategy: "OPEN_AI_STRATEGY",
  },
  data: [],
  error: '',
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH_LEADS_DATA:
      return {
        ...state,
        search: {
          ...state.search,
          loading: true, 
        },
      };
    case isSuccess(actionTypes.SEARCH_LEADS_DATA):
      return {
        ...state,
        search: {
          ...state.search,
          data: action.data,
          error: null,
          loading: false, 
        },
      };
    case isFailure(actionTypes.SEARCH_LEADS_DATA):
      return {
        ...state,
        search: {
          ...state.search,
          data: null,
          error: action.error,
          loading: false,
        },
      };
    case actionTypes.RESET_LEADS_DATA:
      return {
        ...state,
        search: {
          ...state.search,
          data: null,
          error: null,
          loading: false,
        },
      };
    default:
      return state;
  }
}
