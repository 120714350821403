import { useState } from 'react'
import { useSelector } from 'react-redux';
import { getPulseTypeDetails } from 'common/pulseMapping';
import Loader from 'loader';
import Spinner from "../common/spinner";
import { ExpandMore, ExpandLess, TollOutlined } from '@mui/icons-material';

const MarketCards = ({ fromPath, savePulseInSearch, handleSavePulse }) => {
  const pulseSelector = useSelector(state => state.pulses);
  const communitySelector = useSelector((state) => state.community)
  const pulseByCommunity = useSelector((state) => state.community.getPulseByCommunity.data);
  const pulseTypes = pulseSelector.getPulseTypes.data;
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCardExpansion = (key) => {
    setExpandedCard(expandedCard === key ? null : key);
  };

  // TODO: Refactor this spagheti
  // TODO: Refresh pulse should display at the top
  // TODO: Active button should have toggle pulse put attached to it to turn pulse off

  const getAvailableAndAttachedPulses = (attachedPulseTypes, pulseTypes) => {
    const attachedPulses = pulseByCommunity.pulses.flatMap(item => {
      const pulseDetails = getPulseTypeDetails(item.pulse_type);
      return pulseDetails.map(detail => ({
        key: detail.type,
        detail,
      }));
    });

    const availablePulses = Object.entries(pulseTypes.pulse_types)
      .flatMap(([key, value]) => {
        const pulseDetails = getPulseTypeDetails(value);
        return pulseDetails.map(detail => ({
          key: detail.type,
          detail,
        }));
      })
      .filter(({ key, detail }) => !attachedPulseTypes.has(key) && detail.status !== "notStarted");

    return [...attachedPulses, ...availablePulses];
  };

  const getSortedUniqueCards = (cards, attachedPulseTypes) => {
    const statusOrder = { completed: 1, inProgress: 2 };
    return cards
      .sort((a, b) => {
        if (attachedPulseTypes.has(a.key)) return -1;
        if (attachedPulseTypes.has(b.key)) return 1;
        return statusOrder[a.detail.status] - statusOrder[b.detail.status];
      })
      .reduce((acc, current) => {
        if (!acc.some(item => item.key === current.key)) {
          acc.push(current);
        }
        return acc;
      }, []);
  };

  const cards = fromPath === "communities" && pulseByCommunity.pulses.length > 0
    ? getSortedUniqueCards(getAvailableAndAttachedPulses(new Set(pulseByCommunity.pulses.map(item => item.pulse_type)), pulseTypes), new Set(pulseByCommunity.pulses.map(item => item.pulse_type)))
    : getSortedUniqueCards(
      Object.entries(pulseTypes?.pulse_types || {})
        .flatMap(([key, value]) => {
          const pulseDetails = getPulseTypeDetails(value);
          return pulseDetails.map(detail => ({
            key: detail.type,
            detail,
          }));
        })
        .filter(({ detail }) => detail.status !== "notStarted"),
      new Set()
    );

  const getPulseTypes = (data) => {
    return data?.map(item => item.pulse_type) || [];
  };

  const pulseList = getPulseTypes(pulseByCommunity?.pulses);

  const handleButtonClick = (type) => {
    if (fromPath === "search") {
      savePulseInSearch(type);
    } else {
      handleSavePulse(type);
    }
  };

  return (
    <div className="flex flex-col items-center">
      {cards.map(({ key, detail }) => (
        <div key={key} className="relative p-6 rounded-lg max-w-2xl mb-6">
          <div className={`flex items-center mb-4 ${detail.status === "inProgress" ? 'text-gray-400' : ''}`}>
            <div className={`relative flex items-center justify-center rounded-full ${detail.status === "inProgress" ? 'text-gray-300' : ''}`}>
              {detail.icon}
            </div>
            <div className={`ml-4 flex-1 ${detail.status === "inProgress" ? 'text-gray-400' : ''}`}>
              <h2 className={`text-xl font-semibold ${detail.status === "inProgress" ? 'text-gray-400' : ''}`}>
                {detail.title}
              </h2>
              <p className={`${detail.status === "inProgress" ? 'text-gray-400' : 'text-gray-600'}`}>
                {detail.description}
              </p>
            </div>
            {(fromPath === "search" || fromPath === "communities") && pulseByCommunity?.length > 0 ? (
              <div className='flex items-center p-2 px-4 bg-green-500 text-white rounded-full ml-2'>
                {detail.status === "inProgress" ? 'Coming Soon' : (
                  <>
                    <p className='mr-2'>Active</p>
                  </>
                )}
              </div>
            ) : pulseList.includes(detail.type) && (fromPath === "search" || fromPath === "communities") ? (
              <div className='flex items-center p-2 px-4 bg-green-500 text-white rounded-full ml-2'>
                {detail.status === "inProgress" ? 'Coming Soon' : (
                  <>
                    <p className='mr-2'>Active</p>
                  </>
                )}
              </div>
            ) : (
              <div className="relative">
                {pulseSelector.savePulse.loading || communitySelector.getPulseByCommunity.loading ? (
                  <div className="fixed inset-0 bg-gray-300 bg-opacity-50 flex items-center justify-center z-50">
                    <span className="mr-4 text-lg text-white">Loading pulses for community...</span>
                    <div className="relative">
                      <Loader />
                    </div>
                  </div>
                ) : location.pathname === '/pulses' ? (
                  <button
                    className={`ml-4 bg-blue-500 text-white rounded-full px-4 py-2 flex items-center focus:outline-none ${detail.status === "inProgress" ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => toggleCardExpansion(key)}
                    disabled={detail.status === "inProgress"}
                  >
                    {detail.status === "inProgress" ? 'Coming Soon' : 'Example'}
                    {expandedCard === key ? (
                      <ExpandLess className="ml-2" />
                    ) : (
                      <ExpandMore className="ml-2" />
                    )}
                  </button>
                ) : (
                  <button
                    className={`ml-4 bg-blue-500 text-white rounded-full px-4 py-2 ${detail.status === "inProgress" ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => handleButtonClick(detail.type)}
                    disabled={detail.status === "inProgress"}
                  >
                    <div className='flex items-center'>
                      <p>{detail.status === "inProgress" ? 'Coming Soon' : 'Activate'}</p>
                      <p className='ml-1 text-yellow-500'>100</p>
                      <TollOutlined className="text-yellow-500" />
                    </div>
                  </button>
                )}
              </div>
            )}
          </div>
          {expandedCard === key && (
            <div className="mt-4 p-6 bg-gray-100 rounded-lg">
              <p className="text-gray-800 mb-5 font-semibold">{detail.draftExample.subject}</p>
              <div className='border-b border-gray-900 mt-2 mb-4'></div>
              {detail.draftExample.body.split('\n').map((line, index) => (
                <p key={index} className="text-gray-800 mb-4">
                  {line}
                </p>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default MarketCards;

