import { useDispatch, useSelector } from 'react-redux';
import { communityActions } from 'communities/ducks';
// import mockSearch from "../mockSearch.json"


const useCommunitySave = () => {
  const dispatch = useDispatch();
  const searchSelector = useSelector((state) => state.search);
  // const communityIsSaved = useSelector(state => state.community?.saveCommunity?.communityIsSaved); 

  const handleSave = () => {
    dispatch(
      communityActions.saveCommunity({
        query: searchSelector.search.data?.es_query.query,
        leads: searchSelector.search.data?.leads,
        objective: searchSelector.search.data?.objective,
        total: searchSelector.search.data?.total,
        geoId: searchSelector.search.data?.geo_id,
        scrollToken: searchSelector.search.data?.scroll_token,
        // objective: mockSearch.objective,
        // query: mockSearch.es_query.query,
        // leads: mockSearch?.leads,
        // total: mockSearch?.total,
      })
    )
  };

  return { handleSave };
};

export default useCommunitySave;
