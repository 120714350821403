import { actionTypes, actions } from "./ducks";
import api from "../api";
import { takeEvery, put } from "redux-saga/effects";

export default [takeEvery(actionTypes.UPLOAD_IMAGE, onUploadImage)];

function* onUploadImage(action) {
  try {
    const { data: signatureResponse } = yield api.post(
      "/pinball/users/signature",
      {},
      {
        uploadPath: action.uploadPath,
      }
    );
    const form = new FormData();
    Object.keys(signatureResponse.fields).forEach((key) => {
      form.append(key, signatureResponse.fields[key]);
    });
    form.append("Content-Type", "image/");
    form.append("file", action.file);

    yield api.post(
      `https://s3.us-east-2.amazonaws.com/higin`,
      {
        "Content-Type": "multipart/form-data",
      },
      form
    );
    yield put(
      action.next.apply(
        `https://s3.us-east-2.amazonaws.com/higin/${action.uploadPath}`
      )
    );
    yield put(actions.uploadImageSuccess());
  } catch (error) {
    console.warn(JSON.stringify(error));
    const { response } = error;
    yield put(actions.uploadImageFailure(response));
  }
}
