import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: '',
    tokenSet: false,
    loading: false, 
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      state.tokenSet = true;
      state.loading = false; 
    },
    clearAccessToken: (state) => {
      state.accessToken = '';
      state.tokenSet = false; 
      state.loading = false; 
    },
    setLoading: (state) => {
      state.loading = true; 
    },
  },
});

export const { setAccessToken, clearAccessToken, setLoading } = authSlice.actions;
export default authSlice.reducer;
