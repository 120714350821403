import React from "react";
import "./loader.scss";

export default function() {
  return (
    <div className="loader-container relative">
      <div className="circle"></div>
      <div className="circle delay-1"></div>
      <div className="circle delay-2"></div>
    </div>
  );
}
