import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { setAccessToken, setLoading } from '../authSlice';
import { actions } from './ducks';
import Loader from 'loader';

// TODO: Auth0 hooks to be depricated, can push user to auth.pulseco.ai if no token etc

export default function Session({ children }) {
  const { isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useDispatch();
  const authSelector = useSelector(state => state.auth);
  const meSelector = useSelector(state => state.me.meReducer);
  const tokenSet = useSelector(state => state.auth.tokenSet);
  const authLoading = useSelector(state => state.auth.loading);

  useEffect(() => {
    const initializeSession = async () => {
      if (!isAuthenticated && !isLoading) {
        loginWithRedirect();
      } else if (isAuthenticated && !tokenSet && !authLoading) {
        try {
          const token = await getAccessTokenSilently();
          dispatch(setAccessToken(token));
          dispatch(actions.getMe());
        } catch (error) {
          console.error('Failed to fetch access token', error);
          logout({ returnTo: window.location.origin });
        }
      }
    };

    initializeSession();
  }, [
    isAuthenticated, 
    isLoading, 
    getAccessTokenSilently, 
    dispatch, 
    tokenSet, 
    authLoading, 
    loginWithRedirect, 
    logout
  ]);

  if (meSelector.loading || authSelector.loading || isLoading || !authSelector.tokenSet) {
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="mr-4 text-lg">Please wait a moment while we load your profile</span>
        <div className="relative">
          <Loader />
        </div>
      </div>
    );
  }

  if (!isAuthenticated || meSelector.data.detail || meSelector.error) {
    logout({ returnTo: window.location.origin });
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="mr-4 text-lg">Session invalid. Please log in again.</span>
      </div>
    );
  }

  return <Fragment>{children}</Fragment>;
}