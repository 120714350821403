import React from 'react'
import { useSelector } from "react-redux";
import { Person, BookmarkBorderOutlined, Close, ArrowUpward } from '@mui/icons-material';
// import ArrowUpIcon from '@material-ui/icons/ArrowUpwardRounded.js';
import { useAuth0 } from '@auth0/auth0-react';
import useProfilePicture from 'common/useProfilePicture';
import Spinner from 'common/spinner';

const stepOne = () => {
  const meLoading = useSelector((state) => state.me.meReducer.loading)
  const profilePic = useProfilePicture();

  return (
    <div className="flex flex-col items-center justify-start pt-16">
      <div className="flex items-center">
        {meLoading ? (
          <Spinner />
        ) : (
          profilePic ? (
            <img
              src={profilePic}
              referrerPolicy="no-referrer"
              alt="user profile picture"
              className="rounded-full w-12 h-12 mr-2"
            />
          ) : (
            <Person className="rounded-full w-20 h-20 mt-2 mr-2" />
          )
        )}
        <div className="flex items-center justify-between shadow-xl rounded-full bg-gray-200 dark:bg-gray-700 w-full p-4">
          <p className="p-2">Find me VP of Sales who work in the Finance industry and are located in California</p>
          <ArrowUpward className="w-6 h-6 mr-4" />
        </div>
      </div>
    </div>
  )
}

export default stepOne