import { useState } from 'react'
import UsMap from 'maps/us';
import LeadsTable from 'common/leadsTable';
import MarketPlace from 'marketplace';
import { NavClick } from 'common/leads/navClick';
import { ArrowBack } from '@mui/icons-material';

// import mockSearch from "../../mockSearch.json"

// TODO Avoid doing leads.leads will be confusing down the road, clean this

const NavIconAndPage = ({ leads, fromPath, setSelectedCommunityId, selectedObjective }) => {
  const [shouldResetMap, setShouldResetMap] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Map');

  return (
    <>
      <div className="relative bg-white border-b mt-4 z-10">
        {fromPath === 'communities' && (
          <div className='flex'>
            <button
              className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 ml-3 mt-1 mr-4"
              onClick={() => setSelectedCommunityId(null)}
            >
              <ArrowBack />
            </button>

            {selectedObjective && (
              <div className="ml-2 p-3 rounded-lg border border-gray-500">
                <p className='ml-1 mr-1'>{selectedObjective.substring(0, 75) + (selectedObjective?.length > 75 ? '...' : '')}</p>
              </div>
            )}
          </div>
        )}
        <div className="relative flex space-x-4 ml-10">
          <NavClick
            label="Map"
            selected={selectedTab === 'Map'}
            onClick={() => setSelectedTab('Map')}
          />
          <NavClick
            label="People"
            selected={selectedTab === 'List'}
            onClick={() => setSelectedTab('List')}
          />
          {fromPath !== 'search' && (
            <NavClick
              label="Pulses"
              selected={selectedTab === 'Pulses'}
              onClick={() => setSelectedTab('Pulses')}
            />
          )}
        </div>
      </div>
      <div>
        {selectedTab === 'Map' && (
          <div>
            <UsMap leads={leads} shouldResetMap={shouldResetMap} fromPath={fromPath} />
            <div className="absolute bottom-0 right-0 mb-6 mr-6 z-20">
              <button
                className="focus:outline-gray bg-white focus:outline-none shadow-lg border border-gray-200 font-medium rounded-full text-sm px-6 py-3"
                onClick={() => {
                  setShouldResetMap(true);
                  setTimeout(() => {
                    setShouldResetMap(false);
                  }, 1000);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/reset-icon.png`}
                  alt="Map View"
                  className="w-6 h-6"
                />
              </button>
            </div>
          </div>
        )}
        {selectedTab === 'List' &&
          <div className="flex-grow overflow-hidden">
            <LeadsTable leads={leads.leads} />
          </div>
        }
        {selectedTab === 'Pulses' &&
          <MarketPlace
            fromPath={fromPath}
          />}
      </div>
    </>
  )
}

export default NavIconAndPage