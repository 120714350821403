import { isSuccess, isFailure } from "common/util";
import { combineReducers } from "redux";

export const actionTypes = {
  SAVE_PULSE_DATA: "[Pulses] Save pulses",
  RESET_PULSE_SAVED: "[Pulses] Reset pulses saved",
  GET_PULSE_TYPES: "[Pulses] Get pulse types",
  GET_ACTIVATED_PULSES: "[Pulses] Get activated pulse types",
  GET_PULSE_NOTIFICATIONS_BY_USER: "[Pulses] Get pulses by user_id",
  ACKNOLEDGE_PULSE: "[Pulses] Aknowledge Pulse",
};

export const pulseActions = {
  savePulses: (payload) => ({
    type: actionTypes.SAVE_PULSE_DATA,
    payload
  }),
  savePulseSuccess: (data) => ({
    type: isSuccess(actionTypes.SAVE_PULSE_DATA),
    data
  }),
  savePulseFailure: (error) => ({
    type: isFailure(actionTypes.SAVE_PULSE_DATA),
    error,
  }),
  resetPulsesSaved: (data) => ({
    type: actionTypes.RESET_PULSE_SAVED,
    data
  }),
  getActivatedPulseTypes: (payload) => ({
    type: actionTypes.GET_ACTIVATED_PULSES,
    payload
  }),
  getActivatedPulseTypesSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_ACTIVATED_PULSES),
    data
  }),
  getActivatedPulseTypesFailure: (error) => ({
    type: isFailure(actionTypes.GET_ACTIVATED_PULSES),
    error,
  }),
  getPulseTypes: (payload) => ({
    type: actionTypes.GET_PULSE_TYPES,
    payload
  }),
  getPulseTypesSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_PULSE_TYPES),
    data
  }),
  getPulseTypesFailure: (error) => ({
    type: isFailure(actionTypes.GET_PULSE_TYPES),
    error,
  }),
  getPulseNotifications: (payload) => ({
    type: actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER,
    payload
  }),
  getPulseNotificationsSuccess: (data) => ({
    type: isSuccess(actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER),
    data
  }),
  getPulseNotificationsFailure: (error) => ({
    type: isFailure(actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER),
    error,
  }),
  acknowledgePulse: (payload) => ({
    type: actionTypes.ACKNOLEDGE_PULSE,
    payload
  }),
  acknowledgePulseSuccess: (data) => ({
    type: isSuccess(actionTypes.ACKNOLEDGE_PULSE),
    data
  }),
  acknowledgePulseFailure: (error) => ({
    type: isFailure(actionTypes.ACKNOLEDGE_PULSE),
    error,
  }),
};

const savePulseInitialState = {
  community_id: "",
  pulse_type: "",
  payload: {},
  pulsesSaved: false,
  error: "",
  loading: false
};

const getActivatedPulseTypesInitialState = {
  data: [],
  loading: false,
  fetched: false,
  error: null
}

const getPulseTypesInitialState = {
  data: [],
  loading: false,
  fetched: false,
  error: null
}


const getPulseNotificationsInitialState = {
  data: [],
  loading: false,
  fetched: false,
  error: null
};

const aknowledgePulseInitialState = {
  data: [],
  loading: false,
  error: null
};

export const initialState = {
  savePulse: savePulseInitialState,
  getActivatedPulses: getActivatedPulseTypesInitialState,
  getPulseTypes: getPulseTypesInitialState,
  getPulseNotifications: getPulseNotificationsInitialState,
  aknowledgePulse: aknowledgePulseInitialState,
}

function savePulseReducer(state = savePulseInitialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_PULSE_DATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case isSuccess(actionTypes.SAVE_PULSE_DATA):
      return {
        ...state,
        data: action.data,
        pulsesSaved: true,
        error: null,
        loading: false,
      };  
    case isFailure(actionTypes.SAVE_PULSE_DATA):
      return {
        ...state,
        error: action.error,
        loading: false,
      };  
    case actionTypes.RESET_PULSE_SAVED:
      return {
        ...state,
        pulsesSaved: false,
      };  
    default:
      return state;
  }
}

function getActivatedPulsesReducer(state = getActivatedPulseTypesInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_ACTIVATED_PULSES:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case isSuccess(actionTypes.GET_ACTIVATED_PULSES):
      return {
        ...state,
        data: action.data,
        fetched: true,
        error: null,
        loading: false,
      };  
    case isFailure(actionTypes.GET_ACTIVATED_PULSES):
      return {
        ...state,
        error: action.error,
        fetched: true,
        loading: false,
      };  
    default:
      return state;
  }
}

function getPulseTypesReducer(state = getPulseTypesInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_PULSE_TYPES:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case isSuccess(actionTypes.GET_PULSE_TYPES):
      return {
        ...state,
        data: action.data,
        fetched: true,
        error: null,
        loading: false,
      };  
    case isFailure(actionTypes.GET_PULSE_TYPES):
      return {
        ...state,
        error: action.error,
        fetched: true,
        loading: false,
      };  
    default:
      return state;
  }
}

function getPulseNotificationReducer(state = getPulseNotificationsInitialState, action) {
  switch (action.type) {
    case actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER:
      return {
        ...state,
        loading: true,
        fetched: false,
        error: null,
      };
    case isSuccess(actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER):
      return {
        ...state,
        data: action.data,
        fetched: true,
        error: null,
        loading: false,
      };  
    case isFailure(actionTypes.GET_PULSE_NOTIFICATIONS_BY_USER):
      return {
        ...state,
        error: action.error,
        fetched: true,
        loading: false,
      };  
    default:
      return state;
  }
}

function acknowledgePulseReducer(state = aknowledgePulseInitialState, action) {
  switch (action.type) {
    case actionTypes.ACKNOLEDGE_PULSE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case isSuccess(actionTypes.ACKNOLEDGE_PULSE):
      return {
        ...state,
        data: action.data,
        error: null,
        loading: false,
      };  
    case isFailure(actionTypes.ACKNOLEDGE_PULSE):
      return {
        ...state,
        error: action.error,
        loading: false,
      };  
    default:
      return state;
  }
}

const pulsesRootReducer = combineReducers({
  savePulse: savePulseReducer,
  getActivatedPulses: getActivatedPulsesReducer,
  getPulseTypes: getPulseTypesReducer,
  getPulseNotifications: getPulseNotificationReducer,
  acknowledgePulse: acknowledgePulseReducer,
})

export default pulsesRootReducer