import React from "react";
import { Auth0Provider } from '@auth0/auth0-react';
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Communities from './communities/single';
import loadCoin from "./ducks";
import Loader from "loader";
import MarketPlace from './marketplace/index.js';
import Navigator from "./navigator";
import { Provider } from "react-redux";
import Profile from './profile/index.js';
import Onboarding from "./onboarding/index.js";
import SearchCommunities from "./search-leads";
import PulseCards from "pulses/pulseCards";
import Session from './users';
import { useAuth0 } from "@auth0/auth0-react";

// For enhancments and reading -> https://medium.com/@vitorbritto/react-design-patterns-provider-pattern-b273ba665158

const App = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"  //Marking to revist cachlocation, have some security questions here
    >
      <Provider store={loadCoin()}>
        <AppContent />
      </Provider>
    </Auth0Provider>
  );
};

const AppContent = () => {
  const meSelector = useSelector((state) => state.me.meReducer);
  const meLoading = useSelector((state) => state.me.meReducer.loading)
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="mr-4 text-lg">Loading pulse pages...</span>
        <div className="relative">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <Router>
      <Session>
        <>
          {!meLoading && meSelector.data.user_info?.meta.onboarded === false ? (
            <Onboarding />
          ) : (
            <>
              <div className="flex">
                <div className="fixed h-full">
                  <Navigator />
                </div>
                <div className="ml-40 flex-grow overflow-auto h-screen">
                  <Switch>
                    <Route exact path="/" component={SearchCommunities} />
                    <Route path="/communities" component={Communities} />
                    <Route path="/pulses-to-review" component={PulseCards} />
                    <Route path="/pulses" component={MarketPlace} />
                    <Route path="/profile" component={Profile} />
                    <Route path="*">
                      <div className="text-center pt-6">Coming Soon!</div>
                    </Route>
                  </Switch>
                </div>
              </div>
            </>
          )}
        </>
      </Session>
    </Router>
  );
};


export default App;
